import { type ComponentProps, type ReactNode, useEffect, useRef } from "react";

type VisibilityAwareComponentProps = ComponentProps<"div"> & {
  children: ReactNode;
  notVisibleClassName: string;
};

export const VisibilityAwareComponent = ({
  children,
  notVisibleClassName,
  ...props
}: VisibilityAwareComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]: IntersectionObserverEntry[]) => {
        document.body.classList.toggle(
          notVisibleClassName,
          !entry?.isIntersecting,
        );
      },
      {
        root: null,
        rootMargin: "0px",
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
};
